<template>
    <UiEmailSubscription id="SbEmailSubscription" :blok="props.blok" :apr="true" @submit="subscribeEmail" />
</template>

<script setup lang="ts">
import { useEmailSubscriptionStore } from '../../_base/store/emailSubscription';
import type { IEmailSubscriptionConfig } from '@nuxt-monorepo/ui/types';
const { t } = useI18n();
const props = defineProps<{ blok: IEmailSubscriptionConfig }>();
const emailSubscriptionStore = useEmailSubscriptionStore();
const backendError = ref('');

provide('backendError', backendError);

const subscribeEmail = async ({ email }) => {
    return await $publicApi(`newsletters/subscribe`, {
        method: 'POST',
        body: JSON.stringify({ email: email }),
        headers: {
            'Content-Type': 'application/json',
        },
        onResponse({ response }) {
            if (response.ok) {
                emailSubscriptionStore.updateIsSubscribed(true);
                backendError.value = '';
            }
        },
        onResponseError({ response }) {
            if (response) {
                backendError.value = response.message;
            }
        },
    }).catch(() => {
        backendError.value = t('forms.emailUnSubscriptionInvalid2');
    });
};
</script>

<style lang="postcss">
#SbEmailSubscription {
    & + footer {
        @apply -my-8 md:-my-16;
    }
    /* & + .breadcrumbs {
        @apply -mt-8 md:-mt-16;
    } */
    &:has(+ .breadcrumbs),
    &:last-child {
        @apply !mb-0;
    }
}
</style>

<style lang="postcss" scoped>
#SbEmailSubscription {
    @apply px-4 py-6 md:py-16 my-4 md:my-12 bg-brand-primary-500;

    :deep(.container) {
        @apply flex-col justify-center;
        &.content-center {
            .input-wrapper {
                @apply basis-auto;
            }
        }

        .title-wrapper {
            @apply basis-auto;
        }

        h3 {
            @apply apr-section-title text-center;
        }

        p {
            @apply text-base sm:text-xl sm:mb-8 max-w-[535px] mx-auto text-current text-center;
        }

        .success__title,
        .success__subtitle {
            @apply hidden;
        }

        .success {
            @apply flex items-center w-full text-center text-base mb-0 pt-4 px-8 md:w-max font-semibold text-current md:border py-3 rounded-lg border-solid border-current;

            padding: 11.5px 24px 12.5px 24px;

            .iconify {
                @apply w-5 h-5 ml-2;
            }
        }

        form {
            @apply justify-between;

            button {
                @apply mt-3 md:mt-0 ml-0 w-full md:w-auto bg-brand-secondary-500 text-text-primary-asphalt text-center rounded-lg flex-[0] md:ml-3 whitespace-nowrap shrink-0;
                min-width: initial;
                padding: 11.5px 24px 12.5px 24px;
            }

            input {
                @apply pl-4 sm:px-4 py-3 h-12 min-w-[286px] border border-solid border-neutral-mid-grey rounded-lg transition-all duration-[0.33s] ease-[ease-in-out] md:basis-auto;

                &:focus {
                    @apply border-brand-primary-500 outline outline-2 outline-brand-primary-500 outline-offset-0;
                }
                &.has-error {
                    @apply border border-solid border-status-error-red-100 outline outline-2 outline-status-error-red-100 -outline-offset-[3px];
                }

                &::placeholder {
                    @apply text-neutral-mid-grey;
                }
            }
        }

        .input-wrapper {
            @apply relative w-full md:w-auto basis-[100%] pt-4 md:pt-0 md:basis-[85%] lg:basis-[54%];

            .error-tooltip {
                @apply md:absolute !bg-transparent text-left md:bottom-auto md:top-full md:h-max text-sm;

                span {
                    @apply block text-status-error-red-100 text-left;
                }

                &:before,
                &:after {
                    @apply hidden;
                }
            }
        }

        .validation-error {
            @apply hidden;
        }
    }
}

@media screen and (max-width: 768px) {
    #SbEmailSubscription {
        :deep(.container) {
            .title-wrapper {
                @apply w-full;
            }

            .success__title,
            .success__subtitle {
                @apply hidden;
            }
        }
    }
}
</style>
