import { defineStore } from 'pinia';

export const useEmailSubscriptionStore = defineStore('emailSubscription', {
    state: () => ({
        isSubscribed: false,
    }),
    actions: {
        updateIsSubscribed(value: boolean) {
            this.isSubscribed = value;
        },
    },
    persist: true,
});
