<template>
    <section
        class="airport-subscribe"
        :class="{ 'has-bg-color': blok.bgColor?.color }"
        :style="`
            ${blok.bgColor?.color ? `background-color: ${blok.bgColor?.color};` : ''}
            ${blok.paddingY ? `padding-top: ${blok.paddingY}rem;` : ''}
            ${blok.paddingY ? `padding-bottom: ${blok.paddingY}rem;` : ''}
            ${blok.paddingX ? `padding-right: ${blok.paddingX}rem;` : ''}
            ${blok.paddingX ? `padding-left: ${blok.paddingX}rem;` : ''}
            ${blok.textColor?.color ? `color: ${blok.textColor?.color};` : ``}
        `"
    >
        <div
            class="container mx-auto flex flex-row"
            :class="`${blok.contentAlign ? `content-${blok.contentAlign}` : ''}`"
        >
            <div class="title-wrapper">
                <h3>{{ blok.Title }}</h3>
                <p>{{ blok.Text }}</p>
            </div>
            <transition name="fade" mode="out-in">
                <div v-if="!emailSubscriptionStore.isSubscribed" class="input-wrapper">
                    <form class="md:flex md:w-full" novalidate @submit.prevent="submitForm">
                        <input
                            v-model="email"
                            type="email"
                            name="email"
                            :placeholder="blok.InputTextPlaceHolder"
                            class="w-full md:basis-2/3"
                            :class="{ 'has-error': emailValidationError.hasError }"
                            @input="onEmailInput"
                        />
                        <transition name="quick-fade">
                            <div v-if="emailValidationError.hasError" class="error-tooltip">
                                <span>{{ emailValidationError.msg }}</span>
                            </div>
                        </transition>
                        <AdvancedBtn
                            v-if="apr"
                            :with-arrow="true"
                            component-type="button"
                            button-type="submit"
                            component-variant="primary"
                        >
                            {{ blok.SubmitBtnText }}
                        </AdvancedBtn>
                        <button v-else type="submit" class="w-full md:basis-1/3">
                            {{ blok.SubmitBtnText }}
                        </button>
                    </form>
                    <transition name="quick-fade">
                        <div v-if="emailValidationError.hasError" class="validation-error mt-1">
                            <span>{{ emailValidationError.msg }}</span>
                        </div>
                    </transition>
                </div>
                <div v-else class="apr-success-wrapper">
                    <div class="success__title">{{ blok.SubscribedTitle }}</div>
                    <div class="success__subtitle">
                        {{ blok.SubscribedSubTitle }}
                        <button @click="resetSubscriptionEmail">Try again</button>
                    </div>
                    <div class="success">
                        {{ blok.SubscribedTitle }}
                        <NuxtIcon name="ri:thumb-up-line" />
                    </div>
                </div>
            </transition>
        </div>
    </section>
</template>

<script setup lang="ts">
import { useEmailSubscriptionStore } from '../../../apps/_base/store/emailSubscription';
import type { IEmailSubscriptionConfig } from '~ui/types';
import AdvancedBtn from '~ui/components/AdvancedBtn.vue';

const { blok } = defineProps<{ blok: IEmailSubscriptionConfig; apr?: false }>();

const { t } = useI18n();
const emailSubscriptionStore = useEmailSubscriptionStore();
const backendError: any = inject('backendError') || ref('');

const emit = defineEmits<{
    (e: 'submit', formData: { email: string }): void;
}>();

const email: any = ref();

const emailValidationError: { hasError: boolean; msg: string | null } = reactive({
    hasError: false,
    msg: null,
});

const emailValidation = (value: string) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
    );

const validateEmail = () => {
    emailValidationError.hasError = false;
    emailValidationError.msg = null;
    let errorMsg: string | null = null;
    if (!email.value) {
        errorMsg = blok.Error ? blok.Error : t('forms.emailRequired5');
    } else if (!emailValidation(email.value)) {
        errorMsg = blok.InvalidEmailError ? blok.InvalidEmailError : t('forms.invalidEmail2');
    }

    emailValidationError.hasError = !!errorMsg || !!backendError.value;
    emailValidationError.msg = errorMsg || backendError.value;
};

const onEmailInput = () => {
    // if form submit has been triggered and form didn't pass validation
    // revalidate on user input
    if (emailValidationError.hasError) {
        validateEmail();
        backendError.value = '';
    }
};
const submitForm = () => {
    validateEmail();

    if (!emailValidationError.hasError) {
        emit('submit', { email: email.value });
    }
};

watch(
    () => backendError.value,
    () => {
        validateEmail();
    }
);

const resetSubscriptionEmail = () => {
    emailSubscriptionStore.updateIsSubscribed(false);
};
</script>

<style scoped lang="postcss">
.airport-subscribe {
    @apply text-base text-[white] p-12;
    background: #023052;

    .container {
        @apply flex flex-wrap items-center justify-between;

        &.content-start {
            justify-content: start;
        }
        &.content-end {
            justify-content: end;
        }
        &.content-center {
            justify-content: center;

            .title-wrapper {
                @apply basis-auto;
            }
        }
        &.content-space-between {
            @apply justify-between;
        }

        .success__title {
            @apply text-2xl font-normal leading-none;
        }

        .success__subtitle {
            @apply text-sm font-normal leading-6;

            button {
                @apply text-[#44c8fe] underline border-[none];
            }
        }

        h3 {
            @apply font-medium text-[1.53125rem];
        }

        .validation-error {
            @apply text-sm text-[#ff3738];
        }

        .title-wrapper,
        .input-wrapper,
        .apr-success-wrapper {
            @apply md:basis-1/2;
        }

        form {
            input {
                @apply w-full bg-[white] px-4 py-[0.675rem] rounded-[5px] border-[none] text-black;

                &.has-error {
                    @apply border-[0.1rem] border-solid border-[red];
                }
            }

            button {
                @apply bg-[#f98f43] text-[white] px-12 py-[0.675rem] rounded-[5px] border-[none] ml-2;
            }
        }
    }
}

@media (max-width: 768px) {
    .airport-subscribe {
        .container {
            h3 {
                @apply font-medium text-[1.2rem];
            }

            form {
                input {
                    @apply w-full bg-[white] px-4 py-[0.675rem] rounded-[5px] border-[none];
                }

                button {
                    @apply ml-0 mt-2;
                }
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
